<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <!-- 사고종류 -->
          <c-multi-select
            codeGroupCd="IIM_KIND_CD"
            :editable="editable"
            itemText="codeName"
            itemValue="code"
            maxValues="3"
            name="accidentKindCdList"
            label="사고종류"
            v-model="searchParam.accidentKindCdList"
          ></c-multi-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 사고등급 -->
          <c-select
            type="search"
            codeGroupCd="IIM_GRADE_CD"
            itemText="codeName"
            itemValue="code"
            name="accidentGrade"
            label="사고등급"
            v-model="searchParam.accidentGrade"
          ></c-select>
        </div>
          <!-- 진행상태 -->
        <!-- <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            type="search"
            stepperGrpCd="IIM_STATUS_PROCESS_CD"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="accidentStatusCd"
            label="LBLPROGRESS"
            v-model="searchParam.accidentStatusCd"
          ></c-select>
        </div> -->
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 발생부서 -->
          <c-dept
            type="search"
            name="occurrenceDeptCd"
            label="발생부서"
            v-model="searchParam.occurrenceDeptCd"
          ></c-dept>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 발생일 -->
          <c-datepicker
            :range="true"
            name="period"
            label="발생일"
            v-model="period"
          ></c-datepicker>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="공정사고 목록"
      :columns="grid.columns"
      :data="grid.data"
      :selection="popupParam.type"
      rowKey="iimAccidentId"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <div>
          <q-btn-group outline>
            <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
            <c-btn label="LBLSELECT" icon="check" @btnClicked="select" />
          </q-btn-group>
        </div>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: "process-accident-pop",
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'multiple',
          equipmentTypeCd: '',
          grade: '',
          accidentStatusCd: '',
        }
      },
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: "plantName",
            field: "plantName",
            label: "LBLPLANT",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "accidentNo",
            field: "accidentNo",
            label: "사고번호",
            style: 'width:120px',
            align: "center",
            sortable: true,
          },
          {
            name: "accidentName",
            field: "accidentName",
            label: "사고명",
            style: 'width:200px',
            align: "left",
            sortable: true,
          },
          {
            name: "accidentStatusName",
            field: "accidentStatusName",
            style: 'width:100px',
            label: "사고처리단계",
            align: "center",
            sortable: true,
          },
          {
            name: "accidentKindName",
            field: "accidentKindName",
            style: 'width:150px',
            label: "사고종류",
            align: "center",
            sortable: true,
          },
          {
            name: "accidentGradeName",
            field: "accidentGradeName",
            label: "사고등급",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "occurrenceDate",
            field: "occurrenceDate",
            style: 'width:100px',
            label: "발생일시",
            align: "center",
            sortable: true,
          },
          {
            name: "occurrenceDeptName",
            field: "occurrenceDeptName",
            label: "발생부서",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        startYmd: '',
        endYmd: '',
        accidentGrade: null,
        occurrenceDeptCd: '',
        accidentKindCdList: [],
        accidentStatusCd: null,
      },
      period: ['', ''],
      editable: true,
      searchUrl: "",
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        isFull: true,
        top: "",
        param: {},
        closeCallback: null,
      },
      isResult: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      // role setting
      this.listUrl = selectConfig.sop.iim.accident.process.list.url;
      // url setting
      // code setting
      if (this.popupParam.accidentStatusCd) {
        this.searchParam.accidentStatusCd = this.popupParam.accidentStatusCd
      }
      // list setting
      this.getList();
    },
    getList() {
      this.searchParam.startYmd = this.period[0];
      this.searchParam.endYmd = this.period[1];

      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */ 
    onItemClick() {
      this.linkClick(null);
    },
    linkClick(row, col, index) {
      this.popupOptions.title = "공정사고 상세"; // 공정사고 상세
      this.popupOptions.param = {
        iimAccidentId: row ? row.iimAccidentId : '',
        stepCd: row ? row.accidentStatusCd : '',
        count: 0,
      };
      this.popupOptions.target = () =>
        import(`${"./processAccidentDetail.vue"}`);
      this.popupOptions.width = "80%"
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (type === 'REMOVE') {
        window.getApp.$emit('APP_REQUEST_SUCCESS');
      }
      this.getList();
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '공정사고를 선택하세요.', // 설비를 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
  },
};
</script>
